/*-----------------------------------------------------------------------------------

[Table of contents]

1. Font
2. Css Variable for colors
3. Common CSS
4. Preloader CSS
5. Header - Main Navigation ( section )
6. Hero Slider ( section )
7. Trusted Logo Slider ( section )
8. Features ( section )
9. About us ( section )
10. Modern UI ( section )
11. How it works ( section )
12. Testimonials ( section )
13. Pricing ( section )
14. Faq ( section )
15. Interface ( section )
16. Download app ( section )
17. Latest story ( section )
18. Newsletter ( section )
19. Footer ( section )
20. Animation CSS ( section )


-----------------------------------------------------------------------------------*/


/* --------Font--------------- */
@import url('https://fonts.googleapis.com/css2?family=Pixelify+Sans:wght@400;500;600&display=swap');
/* -----------Css-variable------ */

:root {
    --light-purple: #F6F4FE;
    --purple: #02407A;
    --bg-purple: #6A49F2;
    --dark-purple: #32236F;
    --body-text-purple: #3E3F66;
    --text-white: #ffffff;
    --bg-white: #ffffff;
    --slider-dots-color: #D4D2DD;
    --light-bg: #DFDAF3;
}



/* ------Common-Css------------- */

html {
    scroll-behavior: smooth
}

body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-size: 16px;
    line-height: 24px;
    font-family: 'Pixelify Sans', sans-serif;
    color: var(--text-white);
}

.page_wrapper {
    width: 100%;
    overflow-x: hidden;
}

a {
    text-decoration: none;
    color: var(--body-text-purple);
}

a:hover {
    text-decoration: none;
    color: var(--body-text-purple);
}

ul,
li {
    padding: 0;
    list-style-type: none;
    margin: 0;
}

button:focus,
.btn.focus,
.btn:focus {
    outline: none;
    box-shadow: none;
}

@media screen and (min-width:1200px) {
    .container {
        max-width: 1170px;
    }
}

/* section heading h2 */
.section_title h2 {
    font-family: 'Pixelify Sans', sans-serif;
    font-size: 48px;
    font-weight: 700;
    line-height: 1.5em;
    color: var(--text-white);
}

.section_title h2 span {
    color: var(--purple);
}

.row_am {
    padding: 50px 0;
}

/* purple button */
.puprple_btn {
    background-color: var(--purple);
    color: var(--text-white);
    border-radius: 50px;
    padding: 10px 40px;
    position: relative;
    overflow: hidden;
    z-index: 1;
    font-weight: 500;
}

.puprple_btn::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 0%;
    height: 100%;
    background-color: var(--bg-white);
    border-radius: 50px;
    transition: .6s all;
    z-index: -1;
}

.puprple_btn:hover::before {
    width: 100%;
}

.puprple_btn:hover {
    color: var(--purple);
}

/* white button */
.white_btn {
    padding: 10px 45px;
    border: 1px solid var(--purple);
    color: var(--purple);
    border-radius: 50px;
    background-color: var(--bg-white);
    font-weight: 700;
    position: relative;
    z-index: 1;
    overflow: hidden;
    font-weight: 500;
}

.white_btn::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 0%;
    height: 100%;
    background-color: var(--bg-purple);
    border-radius: 50px;
    transition: .6s all;
    z-index: -1;
}

.white_btn:hover::before {
    width: 110%;
}

.white_btn:hover {
    color: var(--text-white);
}

.highlited_block .white_btn:hover {
    border-color: var(--bg-white);
}

/* slider controls */
.owl-carousel .owl-dots {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-top: 20px;
}

.owl-carousel .owl-dots button {
    display: block;
    width: 15px;
    height: 15px;
    background-color: var(--slider-dots-color);
    border-radius: 15px;
    margin: 0 5px;
}

.owl-carousel .owl-dots button.active {
    background-color: var(--purple);
}

/* -------------Preloader-Css-Start-------------- */

/* Preloader */
#preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.9);
    z-index: 999999;
}

#loader {
    display: block;
    position: relative;
    left: 50%;
    top: 50%;
    width: 150px;
    height: 150px;
    margin: -75px 0 0 -75px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: var(--bg-purple);
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}

#loader:before {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: var(--body-text-purple);
    -webkit-animation: spin 3s linear infinite;
    animation: spin 3s linear infinite;
}

#loader:after {
    content: "";
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: var(--dark-purple);
    -webkit-animation: spin 1.5s linear infinite;
    animation: spin 1.5s linear infinite;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}




/* -----------Header-Css-Start------------------- */
/* header wraper */
header {
    position: relative;
    width: 100%;
    z-index: 10;
    transition: .4s all;
}

header.fix_style {
    position: fixed;
    top: 0;
    backdrop-filter: blur(5px);
    background-color: #ffffffb4;
    padding: 15px 0;
    transition: none;
    opacity: 0;
    pointer-events: none;
}

header.fixed {
    pointer-events: all;
    opacity: 1;
    transition: .4s all;
}

header.fixed .navbar {
    padding: 0;
}

.navbar-expand-lg .navbar-collapse {
    justify-content: space-between;
}

ul.navbar-right {
    display: flex;
    align-items: center;
    gap: 24px;
}

ul.navbar-right li a {
    transition: .4s ease-in;
}

ul.navbar-right li a:hover {
    transform: scale(1.2);
}

/* navigation bar */
.navbar {
    padding-left: 0;
    padding-right: 0;
    padding-top: 12px;
}

.navbar-expand-lg .navbar-nav {
    align-items: center;
}

.navbar-expand-lg .navbar-nav .nav-link {
    padding: 0px 20px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--text-white);
}

.navbar-expand-lg .navbar-nav .nav-link:hover {
    color: var(--purple);
}

.navbar-expand-lg .navbar-nav .nav-link.dark_btn {
    color: var(--text-white);
    background-color: var(--purple);
    font-size: 16px;
    padding: 9px 40px;
    border-radius: 25px;
    margin-left: 20px;
    position: relative;
}


.navbar-expand-lg .navbar-nav .nav-link.dark_btn::before,
.navbar-expand-lg .navbar-nav .nav-link.dark_btn::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 42px;
    z-index: -1;
}

.navbar-expand-lg .navbar-nav .nav-link.dark_btn::before {
    animation: pulse-blue-medium-sm 3.5s infinite
}

.navbar-expand-lg .navbar-nav .nav-link.dark_btn::after {
    animation: pulse-blue-small-sm 3.5s infinite
}

.navbar-brand {
    position: relative;
    padding: 0px;
}

.navbar-brand img {
    width: 146px;
}

.navbar-brand:before {
    content: "";
    width: 1px;
    height: 100%;
    display: block;
    background-color: var(--bg-white);
    position: absolute;
    right: -16px;
    top: 0;
    bottom: 0;
}

/* navigation bar dropdown */
.navbar-expand-lg .navbar-nav .has_dropdown {
    display: flex;
    align-items: center;
    position: relative;
    border-radius: 10px 10px 0 0;
    transition: .4s all;
}

.navbar-expand-lg .navbar-nav .has_dropdown:hover {
    background-color: var(--bg-white);
    box-shadow: 0px 4px 10px #c5c5c580;
}

.navbar-expand-lg .navbar-nav .has_dropdown .drp_btn {
    position: relative;
    right: 15px;
}

.navbar-expand-lg .navbar-nav .has_dropdown .sub_menu {
    position: absolute;
    top: 100%;
    background-color: var(--bg-white);
    border-radius: 0 10px 10px 10px;
    min-width: 210px;
    max-width: 230px;
    margin-top: -10px;
    transition: .4s all;
    opacity: 0;
    pointer-events: none;
    box-shadow: 0px 4px 10px #c5c5c580;
}

.navbar-expand-lg .navbar-nav .has_dropdown .sub_menu ul {
    margin-left: 0;
    padding: 10px 20px;
}

.navbar-expand-lg .navbar-nav .has_dropdown .sub_menu ul li a {
    font-size: 15px;
    position: relative;
    transition: .4s all;
    line-height: 35px;
    font-weight: 500;
}

.navbar-expand-lg .navbar-nav .has_dropdown .sub_menu ul li a::before {
    content: "";
    width: 10px;
    height: 10px;
    display: inline-block;
    border: 2px solid var(--purple);
    border-radius: 10px;
    margin-right: 5px;
    position: absolute;
    left: -10px;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0;
    transition: .4s all;
}

.navbar-expand-lg .navbar-nav .has_dropdown .sub_menu ul li a:hover {
    padding-left: 15px;
    color: var(--purple);
}

.navbar-expand-lg .navbar-nav .has_dropdown .sub_menu ul li a:hover::before {
    opacity: 1;
    left: 0;
}

.navbar-expand-lg .navbar-nav .has_dropdown:hover>a,
.navbar-expand-lg .navbar-nav .has_dropdown:hover>.drp_btn {
    color: var(--purple);
}

.navbar-expand-lg .navbar-nav .has_dropdown:hover .sub_menu {
    opacity: 1;
    pointer-events: all;
    margin-top: -1px;
}

/* navigation toggle menu */
.toggle-wrap {
    padding: 10px;
    position: relative;
    cursor: pointer;

    /*disable selection*/
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.toggle-bar,
.toggle-bar::before,
.toggle-bar::after,
.toggle-wrap.active .toggle-bar,
.toggle-wrap.active .toggle-bar::before,
.toggle-wrap.active .toggle-bar::after {
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
}

.toggle-bar {
    width: 25px;
    margin: 10px 0;
    position: relative;
    border-top: 4px solid var(--body-text-purple);
    display: block;
}

.toggle-bar::before,
.toggle-bar::after {
    content: "";
    display: block;
    background: var(--body-text-purple);
    height: 4px;
    width: 30px;
    position: absolute;
    top: -12px;
    right: 0px;
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -ms-transform-origin: 13%;
    -webkit-transform-origin: 13%;
    transform-origin: 13%;
}

.white_header .toggle-bar,
.white_header .toggle-bar::before,
.white_header .toggle-bar::after {
    border-top: 4px solid var(--bg-white);
}

.toggle-bar::after {
    top: 4px;
}

.toggle-wrap.active .toggle-bar {
    border-top: 6px solid transparent;
}

.toggle-wrap.active .toggle-bar::before {
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.toggle-wrap.active .toggle-bar::after {
    -ms-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}