@import url('./default.css');

/* Video section  */

body {
    background-color: #020204;
}

.section-maxWidth {
    padding: 90px 0px;
    max-width: 1440px;
    margin: 0 auto;
}

.section_img {
    position: relative;
}

.section_img img {
    width: 100%;
    object-fit: cover;
}

.section_img video {
    width: 100%;
    border-radius: 16px;
    position: absolute;
    max-width: 320px;
    left: 50%;
    top: 30px;
    transform: translatex(-50%);
    border: 1px solid #676666;
}

.section_img_back {
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    position: absolute;
    width: 300px;
    height: 300px;
    border-radius: 340px;
    background: var(--primary, #4A99E9);
    -webkit-filter: blur(151.31532287597656px);
    filter: blur(151.31532287597656px);
}

.list-browser a {
    background-color: #4A99E9;
    position: relative;
    padding: 8px 12px;
    /* border-radius: 30px; */
    height: 56px;
    -webkit-text-decoration: none;
    text-decoration: none;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    min-width: 150px;
    transition: .4s all;
}

.pixel-btn {
    background-color: #fff;
    clip-path: polygon(0 4px, 4px 4px, 4px 0, calc(100% - 4px) 0, calc(100% - 4px) 4px, 100% 4px, 100% calc(100% - 4px), calc(100% - 4px) calc(100% - 4px), calc(100% - 4px) 100%, 4px 100%, 4px calc(100% - 4px), 0 calc(100% - 4px));
    box-shadow: inset -4px -4px 0px rgba(0, 0, 0, 0.2), inset 4px 4px 0px rgba(255, 255, 255, 0.2);
}

.pixel-btn:hover {
    border-color: #ececec;
}

.qr-code-ton #react-qrcode-logo {
    border-radius: 10px;
}

.hexAddress {
    word-break: break-word;
}

.hexAddress svg {
    cursor: pointer;
}

.tab-content {
    min-height: 75vh;
}

.pixel-btn::before {
    content: "";
    width: 4px;
    height: 4px;
    background-color: rgba(255, 255, 255, 0.2);
    position: absolute;
    top: 4px;
    left: 4px;
}

.pixel-btn::after {
    content: "";
    width: 4px;
    height: 4px;
    background-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    bottom: 4px;
    right: 4px;
}

/* 
.pixel-btn::before {
    content: "";
    display: block;
    position: absolute;
    top: 10px;
    bottom: 10px;
    left: -10px;
    right: -10px;
    background: #4A99E9;
    z-index: -1;
}
  
.pixel-btn::after {
    content: "";
    display: block;
    position: absolute;
    top: 4px;
    bottom: 4px;
    left: -6px;
    right: -6px;
    background: #4A99E9;
    z-index: -1;
} */

.list-browser a img {
    width: 24px;
}

.list-browser a:hover {
    transform: translateY(-10px);
    background-color: #4A99E9;
}

.list-browser {
    margin-top: 25px;
    gap: 15px;
}

.footer_wrap {
    padding: 60px 0px;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    z-index: 1;
    background-position: center;
}

.footer_wrap:after {
    content: "";
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    background-color: #000000a1;
    z-index: -1;
}

.footer-top .navbar-nav {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-top: 10px;
}

.footer-bottom {
    text-align: center;
    margin-top: 10px;
}

.footer-bottom span {
    line-height: 28px;
}

.menu_mobile {
    display: none;
}

.banner_bg_mobile,
.game_story_box_mb,
.show_mb,
.network_container_mb {
    display: none;
}

.show_desktop {
    display: block;
}

.loading {
    position: absolute;
    inset: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.game-wrap {
    width: 100vw;
    height: 100vh;
}

.game-wrap-play {
    width: 100vw;
    height: 75vh;
}

.nav-pills .nav-link {
    color: #fff;
    /* clip-path: polygon(0 4px, 4px 4px, 4px 0, calc(100% - 4px) 0, calc(100% - 4px) 4px, 100% 4px, 100% calc(100% - 4px), calc(100% - 4px) calc(100% - 4px), calc(100% - 4px) 100%, 4px 100%, 4px calc(100% - 4px), 0 calc(100% - 4px));
    box-shadow: inset -4px -4px 0px rgba(0, 0, 0, 0.2), inset 4px 4px 0px rgba(255, 255, 255, 0.2); */
    margin-right: 10px;
    border: 1px solid #fff;
}

.box-pixel-wrap {
    position: relative;
    box-sizing: border-box;
    clip-path: polygon(
            /* top left */
            0 16px, 16px 16px, 16px 0,
            /* top right */
            calc(100% - 16px) 0, calc(100% - 16px) 16px, 100% 16px,
            /* bottom right */
            100% calc(100% - 16px), calc(100% - 16px) calc(100% - 16px), calc(100% - 16px) 100%,
            /* bottom left */
            16px 100%, 16px calc(100% - 16px), 0 calc(100% - 16px));
    box-shadow: inset -8px -8px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    background-color: #363636;
    padding: 40px 30px;
}

.box-pixel-wrap:before {
    box-shadow: inset 8px 8px 0 #fff3;
    content: "";
    height: calc(100% - 24px);
    left: 0;
    position: absolute;
    top: 0;
    width: calc(100% - 24px);
    z-index: -1;
}

.nav-tabs .nav-link {
    color: #fff;
    border: 0px;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    background-color: transparent;
    color: #4999e9 !important;
    border-color: #4999e9 !important;
    border-bottom: 1px solid;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    background-color: #fff;
    color: #000;
}


.pixel-box {
    display: flex;
    flex-direction: column;
    position: relative;
    box-sizing: border-box;
    clip-path: polygon(
            /* top left */
            0 16px, 16px 16px, 16px 0,
            /* top right */
            calc(100% - 16px) 0, calc(100% - 16px) 16px, 100% 16px,
            /* bottom right */
            100% calc(100% - 16px), calc(100% - 16px) calc(100% - 16px), calc(100% - 16px) 100%,
            /* bottom left */
            16px 100%, 16px calc(100% - 16px), 0 calc(100% - 16px));
    box-shadow: inset -8px -8px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.pixel-box::before {
    content: "";
    position: absolute;
    width: calc(100% - 24px);
    height: calc(100% - 24px);
    box-shadow: inset 8px 8px 0 rgba(255, 255, 255, 0.2);
    top: 0;
    left: 0;
    z-index: -1;
}

.convertBox {
    padding: 40px 30px;
    margin: 30px 0;
    background-color: #363636;
}

.convertBox button {
    background-color: #363636;
    color: #fff;
    width: 100%;
    margin-top: 30px;
}

.convertBox button:hover {
    background-color: #0da2d9;
}

.convertBox h3 {
    font-size: 16px;
}

.convertBoxTop,
.convertBoxBottom {
    padding: 20px;
    background-color: #000000;
}

.convertBoxBottom {
    margin-top: 30px;
}

h2.convertBoxTitle {
    font-size: 24px;
    margin-bottom: 15px;
}

.convertBoxIn,
.convertBoxOut {
    align-items: flex-end;
    justify-content: space-between;
    gap: 15px;
    border: 1px solid #fff;
    padding: 10px;
}

.convertBoxIn_Left {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.convertBoxIn_Left h3 {
    font-size: 18px;
    margin-bottom: 0px;
}

.convertBoxIn_Left p {
    margin-bottom: 0px;
}

.convertBoxIn .input-group,
.convertBoxOut .input-group {
    width: 100%;
    border: 0px;
    box-shadow: none;
    min-height: 40px;
    padding-left: 10px;
    font-family: Arial, Helvetica, sans-serif;
}

.number {
    font-family: Arial, Helvetica, sans-serif;
}

#token-amout-output {
    color: #fff;
    display: flex;
    align-items: center;
    background-color: #282828;
}

.loading-wrap {
    position: absolute;
    inset: 0;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #02020285;
    backdrop-filter: blur(5px);
}

.table * {
    font-family: sans-serif;
    font-weight: 300;
}

.loading-send img {
    width: 112px;
    -webkit-animation: pulse 1s ease infinite;
    -moz-animation: pulse 1s ease infinite;
    -ms-animation: pulse 1s ease infinite;
    -o-animation: pulse 1s ease infinite;
    animation: pulse 1s ease infinite;
}

@-webkit-keyframes pulse {
    0% {
        -webkit-transform: scale(1);
    }

    25% {
        -webkit-transform: scale(1.1);
    }

    50% {
        -webkit-transform: scale(1);
    }

    100% {
        -webkit-transform: scale(1);
    }
}

@-moz-keyframes pulse {
    0% {
        -moz-transform: scale(1);
    }

    25% {
        -moz-transform: scale(1.1);
    }

    50% {
        -moz-transform: scale(1);
    }

    100% {
        -moz-transform: scale(1);
    }
}

@-ms-keyframes pulse {
    0% {
        -ms-transform: scale(1);
    }

    25% {
        -ms-transform: scale(1.1);
    }

    50% {
        -ms-transform: scale(1);
    }

    100% {
        -ms-transform: scale(1);
    }
}

@-o-keyframes pulse {
    0% {
        -o-transform: scale(1);
    }

    25% {
        -o-transform: scale(1.1);
    }

    50% {
        -o-transform: scale(1);
    }

    100% {
        -o-transform: scale(1);
    }
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(1);
    }
}

.loading-send {
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 5px;
}

.convertBoxIn .input-group:focus,
.convertBoxOut .input-group:focus {
    outline: none;
}

.convertBoxIn_Right {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 20%;
}

.availableBox {
    display: flex;
    gap: 5px;
    align-items: flex-end;
}

.availableBox span {
    font-family: sans-serif;
}

.convertBoxIcon {
    text-align: center;
    display: flex;
    align-items: center;
    margin: -15px 0px;
    justify-content: center;
}

.convertBoxIcon .icon {
    border: 1px solid;
    border-radius: 99px;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    position: relative;
    background-color: #11b2e5;
}

.coinWrap {
    gap: 8px;
}

.coinWrap img {
    width: 32px;
    height: 32px;
}

.availableBox p {
    margin-bottom: 0px;
}

.availableBox p.text-primary {
    cursor: pointer;
}

.opacity-0 {
    opacity: 0;
}

table th,
table td {
    background-color: #363636 !important;
    color: #fff !important;
    border: 0px;
}

.coin-item img {
    width: 24px;
}

.coin-item span {
    font-family: sans-serif;
}

.nav-item-coin {
    display: flex;
    align-items: center;
    gap: 20px;
}

.coin-item {
    gap: 8px;
}

.user-item img {
    width: 36px;
}

.user-item {
    display: flex;
    align-items: center;
    gap: 8px;
}

.user-name span {
    font-size: 12px;
    display: flex;
    flex-direction: column;
}

.user-item img {
    width: 36px;
}

.user-item {
    display: flex;
    align-items: center;
    gap: 8px;
}

.user-name span {
    font-size: 12px;
    display: flex;
    flex-direction: column;
}

.logout-wrap .btn {
    background-color: #4A99E9;
    position: relative;
    -webkit-text-decoration: none;
    text-decoration: none;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    min-width: 150px;
    transition: .4s all;
    font-family: sans-serif;
}

.nav-item-user {
    display: flex;
    align-items: center;
    gap: 10px;
}

.user-name {
    font-family: sans-serif;
}

.modal-content {
    color: #000000;
    position: relative;
    display: flex;
    flex-direction: column;
    position: relative;
    box-sizing: border-box;

    /* box-shadow: inset -8px -8px 0px rgba(0, 0, 0, 0.2); */
}

/* .modal-content::before {
    content: "";
    position: absolute;
    width: calc(100% - 24px);
    height: calc(100% - 24px);
    box-shadow: inset 8px 8px 0 rgba(255, 255, 255, 0.2);
    top: 0;
    left: 0;
    z-index: -1;
} */

.lds-spinner {
    color: official;
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-spinner div {
    transform-origin: 40px 40px;
    animation: lds-spinner 1.2s linear infinite;
}

.lds-spinner div:after {
    content: " ";
    display: block;
    position: absolute;
    top: 3px;
    left: 37px;
    width: 6px;
    height: 18px;
    border-radius: 20%;
    background: #fff;
}

.lds-spinner div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
}

.lds-spinner div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
}

.lds-spinner div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
}

.lds-spinner div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
}

.lds-spinner div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
}

.lds-spinner div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
}

.lds-spinner div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
}

.lds-spinner div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
}

.lds-spinner div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
}

.lds-spinner div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
}

.lds-spinner div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
}

.lds-spinner div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
}

@keyframes lds-spinner {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

#navbarSupportedContent {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.qr-wrap {
    width: 100vw;
    height: 100vh;
}

.qr-wrap canvas#react-qrcode-logo {
    border-radius: 20px;
}

.commission-wrap {
    max-width: 480px;
    height: 100vh;
    margin: 0 auto;
    padding: 20px 15px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: #f2dac2;
    overflow-y: scroll;
}

.commission-container {
    width: 100%;
}

.commission-top {
    width: 100%;
}

.list-commission {
    margin-top: 30px;
    background-color: #4999e96e !important;
    box-shadow: inset -8px -8px 0 #0003;
    box-sizing: border-box;
    -webkit-clip-path: polygon(0 16px, 16px 16px, 16px 0, calc(100% - 16px) 0, calc(100% - 16px) 16px, 100% 16px, 100% calc(100% - 16px), calc(100% - 16px) calc(100% - 16px), calc(100% - 16px) 100%, 16px 100%, 16px calc(100% - 16px), 0 calc(100% - 16px));
    clip-path: polygon(0 16px, 16px 16px, 16px 0, calc(100% - 16px) 0, calc(100% - 16px) 16px, 100% 16px, 100% calc(100% - 16px), calc(100% - 16px) calc(100% - 16px), calc(100% - 16px) 100%, 16px 100%, 16px calc(100% - 16px), 0 calc(100% - 16px));
    padding: 30px 30px;
    position: relative;
    z-index: 1;
}

.list-commission .commission-item {
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    flex-wrap: wrap;
}

.list-commission .commission-item span {
    font-family: sans-serif;
}

.list-commission .commission-item:last-child {
    margin-bottom: 0px;
}

.list-commission:before {
    box-shadow: inset 8px 8px 0 #fff3;
    content: "";
    height: calc(100% - 24px);
    left: 0;
    position: absolute;
    top: 0;
    width: calc(100% - 24px);
    z-index: -1;
}

.countdown-commission {
    font-size: 20px;
}

.countdown-commission span {
    color: #89ff00;
}

.commission-info-item {
    margin-top: 20px;
    background-color: #3498db !important;
    box-shadow: inset -5px -5px 0 #0003;
    box-sizing: border-box;
    clip-path: polygon(0 6px, 6px 6px, 6px 0, calc(100% - 6px) 0, calc(100% - 6px) 6px, 100% 6px, 100% calc(100% - 6px), calc(100% - 6px) calc(100% - 6px), calc(100% - 6px) 100%, 6px 100%, 6px calc(100% - 6px), 0 calc(100% - 6px));
    padding: 10px 10px;
    position: relative;
    z-index: 1;
    flex: 1;
}

.commission-info-item span {
    display: block;
}

.desc {
    font-family: sans-serif;
}

.commission-info-item:before {
    box-shadow: inset 8px 8px 0 #fff3;
    content: "";
    height: calc(100% - 24px);
    left: 0;
    position: absolute;
    top: 0;
    width: calc(100% - 24px);
    z-index: -1;
}

.list-commission-info {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    align-items: center;
    gap: 10px;
}

.list-history-info {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    align-items: center;
    min-width: 360px;
}

.list-history-info .commission-info-item {
    margin-top: 10px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.list-history-info .commission-info-item span {
    text-align: left;
}

.commission-wrap .navbar-brand:before {
    display: none;
}

.membership-wrap {
    max-width: 480px;
    margin: 0 auto;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f2dac2;
}

.quest-wrap-main {
    max-width: 480px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f2dac2;
}

.quest-wrap .tab-content {
    min-height: auto !important;
}

.convert-wrap {
    background-color: #000;
}

.membership-wrap .navbar-brand img,
.quest-wrap .navbar-brand img {
    width: 200px;
}

.membership-wrap .navbar-brand:before,
.quest-wrap .navbar-brand:before {
    display: none;
}

.membership-item {
    min-width: 75%;
    background-color: #deb78e;
    box-shadow: inset -8px -8px 0 #0003;
    box-sizing: border-box;
    -webkit-clip-path: polygon(0 6px, 6px 6px, 6px 0, calc(100% - 6px) 0, calc(100% - 6px) 6px, 100% 6px, 100% calc(100% - 6px), calc(100% - 6px) calc(100% - 6px), calc(100% - 6px) 100%, 6px 100%, 6px calc(100% - 6px), 0 calc(100% - 6px));
    clip-path: polygon(0 6px, 6px 6px, 6px 0, calc(100% - 6px) 0, calc(100% - 6px) 6px, 100% 6px, 100% calc(100% - 6px), calc(100% - 6px) calc(100% - 6px), calc(100% - 6px) 100%, 6px 100%, 6px calc(100% - 6px), 0 calc(100% - 6px));
    padding: 40px 30px;
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.convert-wrap-item {
    background-color: rgb(37, 41, 60);
}

.membership-item>h3 {
    filter: brightness(110%);
    text-shadow: 0 0 0px #fff, 0 0 1px #fff, 0 0 15px #fff, 0 0 10px #fe7d15,
        0 0 10px #fe7d15, 0 0 10px #fe7d15, 0 0 10px #fe7d15, 0 0 10px #fe7d15;
}

.membership-item h3.price {
    font-family: sans-serif;
}

.membership-item .my-4 {
    display: flex;
    align-items: center;
    gap: 10px;
}

.membership-item .my-4 h3 {
    margin-bottom: 0px;
}

.mess-success,
.mess-err {
    display: block;
    margin-bottom: 10px;
    border: 1px dashed;
    padding: 10px;
    text-align: center;
}

.mess-err {
    margin: 0px 30px 10px 30px;
}

.page-link {
    color: #000;
    box-shadow: inset -5px -5px 0 #0003;
    box-sizing: border-box;
    clip-path: polygon(0 1px, 1px 1px, 1px 0, calc(100% - 1px) 0, calc(100% - 1px) 1px, 100% 1px, 100% calc(100% - 1px), calc(100% - 1px) calc(100% - 1px), calc(100% - 1px) 100%, 1px 100%, 1px calc(100% - 1px), 0 calc(100% - 1px));
}

/* .active>.page-link, 
.page-link.active{
    background-color: #B87333 !important;
    border-color: #B87333 !important;
} */

.pagination {
    gap: 2px;
}

.btn-membership {
    color: #fff;
    background-color: #0377bd;
}

.silver-item,
.package-info-wrap tbody tr:nth-child(2) td,
.silver-item td {
    background-color: #C0C0C0 !important;
}

.gold-item,
.package-info-wrap tbody tr:nth-child(3) td,
.gold-item td {
    background-color: #FFD700 !important;
}

.package-info-wrap tbody tr:last-child td,
.diamond-item td {
    background-color: #0377bd !important;
}

.copper-item,
.package-info-wrap tbody tr:first-child td,
.copper-item td {
    background-color: #B87333 !important;
}

.package-info-wrap {
    width: 100%;
}

.package-info-wrap .desc {
    font-size: 12px;
}

.commission-info-item button {
    font-size: 11px;
}

.nav-webapp {
    justify-content: center;
}

.nav-webapp .nav-link {
    font-size: 10px;
    padding: 5px;
}

.box-pixel-wrap {
    padding: 10px 15px;

}

.nav-link {
    font-size: 12px;
    padding: 5px;
}

.nav.nav-tabs {
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.convert-desktop-wrap .nav.nav-tabs {
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

.convertBoxTop {
    padding: 8px;
}

.convertBox.pixel-box {
    padding: 20px
}

.convertBoxTitle,
.convertBoxTop * {
    font-size: 16px !important;
}

.qr-code-ton-webapp {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    height: 100vh;
    width: 100vw;
}

.qr-code-ton-webapp .hexAddress {
    display: block;
}

.convert_section {
    max-width: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    gap: 10px;
}

.convertBoxTop .btn.pixel-btn {
    width: 100%;
    background: #0377bd;
}

.text-message {
    font-family: sans-serif !important;
}

.convertBox .commission-info-item.copper-item {
    grid-template-columns: repeat(1, 1fr);
    font-size: 12px;
}

.convertBox .list-history-info {
    min-width: auto;
}

.list-referral-wrap thead td {
    background-color: #b77334 !important;
}

.list-referral-wrap tbody tr td,
.list-referral-wrap tbody tr:first-child td {
    background-color: #000 !important;
}

.list-referral-wrap tbody tr:nth-child(2n) td {
    background-color: #252a3c !important;
}

.quest-container li.nav-item {
    flex: 1;
}

.quest-container .nav-pills {
    margin: 0 auto;
    margin-bottom: 20px;
    background-color: #feb865;
    border-radius: 8px;
    overflow: hidden;
    padding: 4px;
    gap: 20px;
    width: 75%;
}

.quest-container .nav-pills li button {
    width: 100%;
}

.reward-points-top h3 {
    font-family: sans-serif;
    font-weight: 600;
    font-size: 16px;
    margin-top: 10px;
    display: block;
    max-width: 375px;
}

.reward-points-top img {
    width: 32px;
}

.reward-points-top {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.quest-container h3 {
    font-size: 18px;
    color: #000;
    display: inline-block;
}

.daily_section {
    max-width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    margin: 0 auto;
}

.daily_section .convertBox {
    background-color: #feb865;
}

.daily_section .pixel-box {
    margin-top: 0px;
    clip-path: polygon(
            /* top left */
            0 6px, 6px 6px, 6px 0,
            /* top right */
            calc(100% - 6px) 0, calc(100% - 6px) 6px, 100% 6px,
            /* bottom right */
            100% calc(100% - 6px), calc(100% - 6px) calc(100% - 6px), calc(100% - 6px) 100%,
            /* bottom left */
            6px 100%, 6px calc(100% - 6px), 0 calc(100% - 6px));
}

.quest-box-right {
    display: flex;
    align-items: center;
    gap: 3px;
}

.quest-box-right span {
    font-family: sans-serif;
}

.quest-box-right img {
    width: 16px;
}

.quest-box-left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.quest-box-left button {
    width: 100px !important;
}

.quest-box-left .quest-title {
    color: #feb865;
    font-size: 18px !important;
}

.check-icon {
    display: inline-block;
    border: 2px solid #198754;
    min-width: 75px;
    border-radius: 5px;
    background-color: white;
    margin-top: 5px;
    text-align: center;
}

.daily_section .convertBoxTop {
    text-align: left;
}

.check-icon svg {
    color: #198754;
}

.join-channel {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: space-between;
    border: 1px solid;
    padding: 5px;
    border-radius: 8px;
    margin: 10px 0px;
}

.join-channel svg {
    width: 64px;
    font-size: 24px;
}

.join-channel-title span {
    font-size: 12px !important;
    text-align: left;
    display: block;
}

.join-channel-title span:first-child {
    color: #feb865;
    line-height: 10px;
    font-size: 14px !important;
}

.join-channel-title {
    flex: 1;
    width: 100%;
}

.join-channel-title span img{
    width: 20px;
}

.btn-checkin {
    width: 150px !important;
}

.font-sm {
    font-size: 12px !important;
    line-height: 1.3em;
}

.join-channel .pixel-btn {
    color: #fff;
    width: 50px !important;
}

.mainApp-wrap {
    max-width: 480px;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    margin: 0 auto;
    font-family: sans-serif;
}

.quest-wrap {
    background: #feb865;
    box-shadow: -5px 0 0 0 white, 5px 0 0 0 white, 0 -5px 0 0 white, 0 5px 0 0 white;
}

.mainApp-wrap-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 160px);
    width: 100%;
    box-shadow: -5px 0 0 0 white, 5px 0 0 0 white, 0 -5px 0 0 white, 0 5px 0 0 white;
    padding: 10px;
}

.mainApp-wrap-container-wrap {
    width: 95%;
}

.mainApp-wrap-container-wrap .nav-pills {
    justify-content: center;
    gap: 10px;
}

.mainApp-wrap-container-wrap .tab-content {
    min-height: calc(100vh - 150px);
}


.mainApp-wrap-container-wrap .nav-pills li button {
    margin-right: 0px;
    min-width: 60px;
    border-radius: 99px;
}

.mainApp-wrap-container-wrap .nav-pills li button.active {
    clip-path: polygon(0 4px, 4px 4px, 4px 0, calc(100% - 4px) 0, calc(100% - 4px) 4px, 100% 4px, 100% calc(100% - 4px), calc(100% - 4px) calc(100% - 4px), calc(100% - 4px) 100%, 4px 100%, 4px calc(100% - 4px), 0 calc(100% - 4px));
    box-shadow: inset -4px -4px 0px rgba(0, 0, 0, 0.2), inset 4px 4px 0px rgba(255, 255, 255, 0.2);
}

.mainApp-logo {
    width: 150px;
    height: 50px;
    object-fit: cover;
}

.mainApp-header {
    width: 100%;
    padding: 10px;
    justify-content: space-between;
    align-items: flex-start;
}

.mainApp-header-left {
    text-align: left;
}

.mainApp-header-left .mainApp-userName {
    margin-bottom: 10px;
}

.mainApp-header-right .btn.pixel-btn {
    background-color: #4A99E9;
    color: #fff;
}

.wallet-item {
    clip-path: polygon(
            /* top left */
            0 6px, 6px 6px, 6px 0,
            /* top right */
            calc(100% - 6px) 0, calc(100% - 6px) 6px, 100% 6px,
            /* bottom right */
            100% calc(100% - 6px), calc(100% - 6px) calc(100% - 6px), calc(100% - 6px) 100%,
            /* bottom left */
            6px 100%, 6px calc(100% - 6px), 0 calc(100% - 6px));
    background-color: rgb(37, 41, 60);
    padding: 10px;
}

.wallet-item-text .hexAddress {
    display: flex;
    align-items: center;
    gap: 5px;
}

.wallet-item-text .hexAddress span {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: 12px;
    flex: 1;
    text-align: left;
}

.alert-danger {
    font-size: 12px;
    padding: 5px;
    text-align: left;
    line-height: 1.5em;
    border-left: 10px solid #f1aeb5;
}

.invite_code_title {
    text-align: left;
    font-size: 20px;
    margin-bottom: 10px;
}

.invite_code_container {
    clip-path: polygon(
            /* top left */
            0 6px, 6px 6px, 6px 0,
            /* top right */
            calc(100% - 6px) 0, calc(100% - 6px) 6px, 100% 6px,
            /* bottom right */
            100% calc(100% - 6px), calc(100% - 6px) calc(100% - 6px), calc(100% - 6px) 100%,
            /* bottom left */
            6px 100%, 6px calc(100% - 6px), 0 calc(100% - 6px));
    background-color: #252a3c;
    padding: 10px;
    box-shadow: inset -8px -8px 0px rgba(0, 0, 0, 0.2);
    min-height: 70px;
}

.invite_code_container button {
    width: 90px !important;
}

.invite_code_text {
    font-size: 20px;
}

.wallet-item-adress {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: space-between;
}

.wallet-item-adress .wallet-item-text {
    padding: 10px;
    background: rgb(37, 41, 60);
    border-radius: 99px;
    flex: 0 0 65%;
}

.wallet-item-adress .wallet-item-text svg {
    flex: 0 0 32px;
}

.private_key_list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    font-size: 14px;
    color: #fff;
    position: relative;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #252a3c;
    border-radius: 16px;
    padding: 10px;
    overflow: hidden;
    gap: 5px;
}

.private_key_list li {
    background-color: #000;
    border-radius: 5px;
}

.private_key_list:after {
    content: "";
    position: absolute;
    inset: 0;
    backdrop-filter: blur(9px);
}

.detail_list {
    font-size: 14px;
    color: #fff;
    position: relative;
    text-align: left;
    background-color: #252a3c;
    border-radius: 16px;
    padding: 10px;
}

.btn-show-key {
    color: #000 !important;
}

.private_key_list.active:after {
    backdrop-filter: blur(0);
}

.private_key_wrap {
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    background-color: #000;
    border-radius: 16px;
    padding: 30px 15px 30px 15px;
    opacity: 0;
    transform: translateY(100%);
    transition: .4s all;
}

.private_key_wrap.active {
    opacity: 1;
    transform: translateY(0%);
    transition: .4s all;
}

.coin-item-wrap img {
    width: 32px;
    border-radius: 99px;
}

.coin-item-wrap {
    margin-top: 30px;
    background-color: #000;
    padding: 10px 20px;
    border-radius: 16px;
}

.wallet-item-text {
    background-color: black;
    padding: 5px 10px;
    border-radius: 99px;
}

.main-app-modal .modal-content {
    clip-path: unset;
    font-family: sans-serif;
    background-color: #252a3c;
    color: #fff;
}

.main-app-modal .modal-content .wallet-item-adress {
    background-color: #000;
    border-radius: 16px;
}

.main-app-modal .modal-content .wallet-item-adress button {
    flex: 0 0 max-content;
    font-size: 12px;
}

.main-app-modal .modal-content .wallet-item-adress .wallet-item-text {
    flex: 1;
}

.modal.fade .modal-dialog {
    backdrop-filter: blur(20px);
}

.main-app-modal .modal-content .modal-header .btn-close {
    color: #fff;
    background-color: #fff !important;
    opacity: 1;
}

.mainApp-wrap-container-wrap .btn-membership {
    font-size: 12px;
}

.mainApp-wrap-container-wrap .convert-wrap-item {
    padding: 10px;
}

/* .convert-app-tab {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    width: 100%;
} */

.convert-app-tab button {
    width: 100%;
    border-radius: 8px !important;
}

.tplant-icon-absolute {
    width: 36px;
    position: absolute;
    right: 6px;
    top: 2px;
}

.position-relative #token-amout-input {
    padding-right: 41px;
}

.convert-app-main .qr-code-ton-webapp {
    height: auto;
    padding: 10px;
}

.convert-app-main .qr-code-ton-webapp {
    height: auto;
    padding: 10px;
}

.convert-app-main .tab-content {
    min-height: unset !important;
    width: 100%;
}

.convert-app-main .mainApp-wrap-container {
    height: auto;
    box-shadow: none;
}

.convert-app-main .convertBoxTop,
.convert-app-main .convertBoxBottom {
    border-radius: 16px;
    padding: 16px;
}

.convert-app-main .convertBoxIn,
.convert-app-main .convertBoxOut {
    flex-direction: column;
}

.convert-app-main .convertBoxIn_Left {
    width: 100%;
}

.convert-app-main .convertBoxIn_Right {
    width: 100%;
}

.main-app-modal .modal-content {
    height: 100vh;
}

.main-app-modal {
    overflow: hidden;
}

.PIN-wrap {
    gap: 5px;
    justify-content: center;
}

.PIN-wrap input.form-control {
    width: 44px !important;
    height: 44px !important;
    padding: 0px;
    font-weight: bold;
    font-size: 20px;
}

.pincode-app .modal-content {
    height: auto;
}

.close_detail {
    position: absolute;
    right: 10px;
    background-color: white;
    color: #000;
    border-radius: 4px;
}

.home-mainApp-list-coin .coin-item-wrap {
    background: #1f2544;
}

.private_key_wrap .pixel-btn:hover *,
.private_key_wrap .pixel-btn:hover {
    background-color: #fff;
}

.mainApp-wrap-container-wrap .list-referral-wrap thead td {
    background-color: #1f2544 !important;
}

.private_key_wrap hr {
    border-top-color: #fff;
    opacity: 1;
}

.modal-bg-dark .modal-content {
    background-color: #000;
}

.modal-bg-dark .modal-content .coin-item-wrap {
    background-color: #252a3d;
}

.load-pixel {
    position: absolute;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    z-index: 10;
    padding: 30px;
    transition: .4s all;
}

.load-pixel img {
    width: 64px;
}

.private_key_list.active .load-pixel {
    display: none;
    opacity: 0;
}

.rate-item {
    border: 1px dashed #4999e9;
    background-color: transparent !important;
    border-radius: 5px;
}

.tw-wrap-username {
    width: 75%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 16px;
    border: 1px dashed;
    padding: 5px;
}

.tw-wrap-username input#tw-username {
    min-height: 40px;
    padding: 0px 10px;
}

.tw-wrap-username input#tw-username:focus {
    outline: none;
}

.tw-wrap-username .btn-dark {
    background-color: #000 !important;
}

.btn-primary {
    background-color: #0377bd !important;
}

.btn-dark {
    background-color: #000 !important;
}

.tw-quest-box img {
    width: 24px;
}

.tw-quest-box span {
    font-size: 24px !important;
}

.market-container {
    background-color: #2C3E58;
    border-radius: 16px;
    padding: 10px 16px;
    margin-bottom: 10px;
}

.market-header {
    background-color: #1A293E;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    font-size: 24px;
}

.market-body-wrap{
    height: calc(100vh - 150px);
    overflow-y: scroll;
    position: relative;
    background-color: #AEC4D6;
    border-radius: 8px;
    padding-left: 5px;
}

.market-body {
    background-color: #AEC4D6;
    margin-top: 10px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: flex-start;
    padding: 10px;
    gap: 5px;
    border-radius: 8px;
   
}

.market-body-wrap::-webkit-scrollbar-track
{
	-webkit-box-shadow: none;
	background-color: #AEC4D6;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

.market-body-wrap::-webkit-scrollbar
{
	width: 5px;
	background-color: #AEC4D6;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}

.market-body-wrap::-webkit-scrollbar-thumb
{
    border-radius: 8px;
	background-color: #F90;	
	background-image: -webkit-linear-gradient(45deg,
	                                          rgba(255, 255, 255, .2) 25%,
											  transparent 25%,
											  transparent 50%,
											  rgba(255, 255, 255, .2) 50%,
											  rgba(255, 255, 255, .2) 75%,
											  transparent 75%,
											  transparent)
}

.market-body-wrap .coin-item-wrap{
    margin-top: 8px;
    background-color: #252a3c;
}

.market-body-wrap .invite_code_container{
    clip-path: none;
    border-radius: 16px;
    padding: 8px;
    min-height: auto;
}

span.buyer span {
    overflow: hidden;
    width: 90px;
    font-size: 12px;
}

span.buyer {
    font-size: 12px;
}

.prod-item {
    background-color: #586D88;
    border-radius: 8px;
    padding: 5px;
}

.prod-item .prod-img img {
    width: 64px;
}

.prod-item .prod-price img {
    width: 20px;
}

.prod-title {
    text-align: left;
    font-size: 12px;
}

.font-md {
    font-size: 16px !important;
    line-height: 1.3em;
}

.prod-item .pixel-btn {
    font-size: 12px;
    background-color: #4999e9;
    color: #fff;
    display: inline-flex;
    width: 75%;
    justify-content: center;
    margin: 0 auto;
    margin-top: 5px;
    position: relative;
}

.staking-wrap{
    background-color: #2C3E58;
    border-radius: 16px;
    padding: 16px;
    margin-bottom: 10px;
}

.box-header-list {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 16px;
    align-items: center;
    margin-bottom: 10px;
    background-color: #AEC4D6;
    border-radius: 8px;
    padding: 8px;
}

.box-text .text-large {
    font-weight: 600;
    display: block;
}

.box-header-part {
    justify-content: center;
    background-color: #586D88;
    border-radius: 8px;
}

.box-text span:not(span.text-large) {
    font-size: 13px;
    color: var(--white);
    display: block;
}

.box-text-earned .text {
    font-size: 14px;
}

.box-text-earned .earn-total {
    font-size: 18px;
    font-weight: 600;
    color: #f8a41a;
}

.box-text-apr span.text-large {
    font-size: 14px;
    font-weight: 600;
    display: block;
}

.box-text-apr span.number {
    font-weight: 600;
    font-size: 15px;
    display: block;
}

.box-text-staked span.text-large {
    font-size: 14px;
    display: block;
}

span.number-total-staked {
    font-size: 15px;
    font-weight: 600;
    display: block;
}

.box-text-end span.text-large {
    font-size: 14px;
    display: block;
}

.icon-expand {
    color: #4A99E9;
}

.box-content-wrapper {
    background-color: #AEC4D6;
    border-radius: 8px;
    padding: 20px 16px !important;
}

.text-view-info>div span:last-child {
    font-weight: 600;
}

.text-view-info:not(:first-child) {
    margin-bottom: 5px;
    font-size: 15px;
}

.box-harvest {
    background-color: #586D88;
    padding: 8px;
    border-radius: 8px;
    display: flex;
    align-items: flex-end;
    gap: 10px;
    height: 100%;
    justify-content: space-between;
}

.harvest-left {
    flex: 1;
    text-align: left;
}

.harvest-left .total-coin span {
    color: #fff;
    font-weight: 600;
}

.harvest-left .total-coin {
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}

.text-harvest,
.text-staking {
    margin-bottom: 0px;
}

.total-usd {
    display: flex;
    align-items: center;
    gap: 5px;
    justify-content: flex-start;
}

.total-usd span,
.total-usd {
    color: #fff;
}

.total-coin {
    justify-content: center;
}

.btn-harvest {
    background-color: #4A99E9 !important;
    color: #fff;
}

.box-staking .total-coin span {
    color: #4A99E9;
    font-weight: 600;
    color: #fff;
}

.box-staking .btn-minus.btn {
    background-color: #4A99E9;
    flex: 1;
    color: #fff;
}

.box-staking .btn-plus.btn {
    background-color: #4A99E9;
    margin-left: 10px;
    color: #fff;
    flex: 1;
}

.box-staking {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    background-color: #586D88;
    padding: 8px;
    border-radius: 8px;
}

.box-staking .btn-connectWallet {
    background-color: #4A99E9;
    color: #fff;
}

.text-view-info {
    display: flex;
    align-items: center;
    gap: 5px;
}

.no-data {
    margin-top: 20px;
    background-color: #3498db !important;
    box-shadow: inset -5px -5px 0 #0003;
    box-sizing: border-box;
    clip-path: polygon(0 6px, 6px 6px, 6px 0, calc(100% - 6px) 0, calc(100% - 6px) 6px, 100% 6px, 100% calc(100% - 6px), calc(100% - 6px) calc(100% - 6px), calc(100% - 6px) 100%, 6px 100%, 6px calc(100% - 6px), 0 calc(100% - 6px));
    padding: 10px 10px;
    position: relative;
    z-index: 1;
}

/* mobile */
@media screen and (max-width: 767px) {

    .convertBoxIn,
    .convertBoxOut {
        flex-direction: column;
    }

    .convertBoxIn_Left {
        width: 100%;
    }

    .convertBoxIn_Right {
        width: 100%;
    }

    .convertBoxOut .convertBoxIn_Right .availableBox {
        display: none;
    }

    .convertBoxIcon {
        margin: -10px 0px;
    }

    #navbarSupportedContent .navbar-nav {
        display: none;
    }

    #navbarSupportedContent ul.navbar-right {
        justify-content: flex-start;
        flex-wrap: wrap;
        gap: 10px;
    }

    div#navbarSupportedContent {
        width: 100%;
    }

    .navbar-brand img {
        width: 115px;
    }

    .show_mb {
        display: block;
    }

    .show_desktop {
        display: none;
    }

    .network_container_mb {
        display: block;
    }


    .menu_mobile {
        position: fixed;
        z-index: 999;
        width: 100%;
        height: 100%;
        top: 0;
        padding: 20px;
        background: #020a1345;
        /* box-shadow: 0px 0px 250px 0px #1F50C7 inset, 0px 0px 50px 0px #3B66D0 inset, 0px 4px 50px 0px rgba(86, 132, 246, 0.60); */
        backdrop-filter: blur(10px);
        transform: translateX(-100%);
        opacity: 0;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        transition: .4s all;
        display: block;
    }

    .menu_mobile.active {
        transform: translateX(0%);
        opacity: 1;
    }

    .menu_mobile ul.navbar-nav {
        height: 100%;
        align-items: center;
        justify-content: center;
        gap: 20px;
    }

    .colse_mb {
        position: absolute;
        right: 20px;
        width: 40px;
        height: 40px;
        cursor: pointer;
        transition: .4s all;
    }

    .colse_mb:hover {
        animation: rotateAnimation 2s linear 0s infinite;
        -webkit-animation: rotateAnimation 2s linear 0s infinite;
        -moz-animation: rotateAnimation 2s linear 0s infinite;
        -o-animation: rotateAnimation 2s linear 0s infinite;
    }

    .colse_mb img {
        width: 40px;
        height: 40px;
    }

    .section_title h2 {
        font-size: 31px;
    }

    .navbar-brand:before,
    .game_story_box {
        display: none;
    }

    .list-browser a {
        height: 40px;
    }

    .list-browser {
        flex-wrap: wrap;
    }

    .section_img {
        margin-top: 30px;
    }

    .footer-top .navbar-nav {
        flex-wrap: wrap;
        gap: 20px;
    }

    .footer-bottom h2 {
        width: 100%;
    }

    .footer_wrap {
        background-position: center;
    }

    .footer-bottom img {
        width: 100%;
        transform: translateY(-15px) scale(1.2);
    }

    .market_slide .swiper-slide-active .market_item {
        transform: scale(1);
    }

}